.upload-profil-pic-container {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	z-index: 99;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	.upload-profil-pic {
		position: relative;
		width: 70%;
		min-width: 500px;
		max-width: 900px;
		text-align: center;
		border: 1px solid $fds-gray-10;
		padding: 15px;
		-webkit-box-shadow: 0px 18px 20px 5px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 18px 20px 5px rgba(0, 0, 0, 0.1);
		h3 {
			font-size: 20px;
			margin-bottom: 20px;
		}
		.close-window {
			position: absolute;
			right: 15px;
			top: 10px;
		}
		.upload-pic-container {
			margin-top: 20px;
			label {
				padding: 7px 50px;
				font-size: 15px;
				font-weight: 500;
				background-color: $user-btn-bg;
				color: $user-btn-text;
				border-radius: 5px;
				cursor: pointer;
				transition: 300ms;
				&:hover {
					filter: brightness(97%);
				}
				&:active {
					filter: brightness(93%);
				}
			}
			input[type="file"] {
				display: none;
			}
			.img-submit {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				input[type="submit"] {
					padding: 7px 50px;
					margin-top: 15px;
					background-color: $primary-btn-bg;
					color: white;
					font-size: 15px;
					font-weight: 500;
					border-radius: 5px;
					cursor: pointer;
					transition: 100ms;
					&:hover {
						filter: brightness(94%);
					}
					&:active {
						filter: brightness(90%);
						transform: scale(0.99);
					}
				}
				.img-preview {
					width: 350px;
					height: 350px;
					object-fit: cover;
					&-banner {
						width: 100%;
						height: 300px;
					}
				}
			}
			.upload-profil-pic-preview {
				position: absolute;
				width: 350px;
				height: 350px;
				background-color: rgba(255, 255, 255, 0.5);
				.upload-profil-pic-preview-visible {
					width: 350px;
					height: 350px;
					border-radius: 50%;
				}
				.img-preview-visible {
					width: 350px;
					height: 350px;
					object-fit: cover;
					border-radius: 50%;
				}
			}
		}
	}
}
