@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.pokemon-window {
    height: 100%;
    background-color: black;
}

.pokemon-app {
    padding-top: 2%;
    background-color: black;
    height: 100%;
    * {
        text-align: left;
        font-family: 'Press Start 2P', cursive;
    }
    // body {
    //     background-color: #020304;
    //     overflow: hidden;
    // }
    h2 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 16px;
        font-size: 20px;
        color: white;
        border: 2px solid white;
        cursor: pointer;
        user-select: none;
        &:hover {
            opacity: 0.6;
        }
        &:active {
            opacity: 0.6;
        }
    }
    .game-container {
        position: relative;
        width: 512px;
        height: 288px;
        margin: 0 auto ;
        outline: 1px solid #fff;
        overflow: hidden;
        transform-origin: top;    
        -webkit-box-shadow: 0px 15px 30px 2px #1a3369; 
        box-shadow: 0px 15px 30px 2px #1a3369;
        canvas {
            position: absolute;
            image-rendering: pixelated;
            transform: translateY(-112px) translateX(-208px);
            transition: 1000ms;
        }
        
    }
}