.player-animation {
	position: absolute;
	top: 0;
	width: 200px;
	height: 100%;
	z-index: 2;
	pointer-events: none;
}
.enemy-animation {
	position: absolute;
	top: 0;
	right: 0;
	width: 200px;
	height: 100%;
	z-index: 2;
	pointer-events: none;
}

.battle-movement-right {
	animation: battle-movement-right 0.6s;
}
@keyframes battle-movement-right {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(15px);
	}
	100% {
		transform: translateX(0);
	}
}

.battle-movement-left {
	animation: battle-movement-left 0.6s;
}
@keyframes battle-movement-left {
	0% {
		transform: translateX(0);
	}
	25% {
		transform: translateX(-15px);
	}
	100% {
		transform: translateX(0);
	}
}
.battle-movement-up {
	animation: battle-movement-up 0.6s;
}
@keyframes battle-movement-up {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(-15px);
	}
	100% {
		transform: translateY(0);
	}
}

.battle-movement-rotate {
	animation: battle-movement-rotate 1s;
	animation-iteration-count: 2;
	transform-origin:100%;
}
@keyframes battle-movement-rotate {
	0% {
		transform:rotate(0deg)
		translateX(-10px)
		translateY(0)
		rotate(0deg);
	}
	100% {
			transform:rotate(360deg)
			translateX(-10px)
			translateY(0)
			rotate(-360deg);
	}
}

@keyframes fade-in-out {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.8;
	}
	100% {
		opacity: 0;
	}
}


.LOW-ATK {
	z-index: 0;
	&::after {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-image: url(../../../assets/graphics//battle/animations/statEffect.gif);
		background-repeat: no-repeat;
		background-size: 100%;
		animation-name: stat-handler;
		animation-duration: 1.4s;
		animation-delay: 1200ms;
		transform:  scale(0.8) translateY(90px);
		filter: brightness(0) saturate(100%) invert(9%) sepia(74%) saturate(6373%) hue-rotate(359deg) brightness(98%) contrast(112%);
		opacity: 0;
	}
}
.LOW-DEF {
	z-index: 0;
	&::after {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-image: url(../../../assets/graphics//battle/animations/statEffect.gif);
		background-repeat: no-repeat;
		background-size: 100%;
		animation-name: stat-handler;
		animation-duration: 1.4s;
		animation-delay: 1200ms;
		transform:  scale(0.8) translateY(90px);
		filter: brightness(0) saturate(100%) invert(36%) sepia(94%) saturate(1297%) hue-rotate(91deg) brightness(99%) contrast(107%);
		opacity: 0;
	}
}
.LOW-SPD {
	z-index: 0;
	&::after {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-image: url(../../../assets/graphics//battle/animations/statEffect.gif);
		background-repeat: no-repeat;
		background-size: 100%;
		animation-name: stat-handler;
		animation-duration: 1.4s;
		animation-delay: 1200ms;
		transform:  scale(0.8) translateY(90px);
		filter: brightness(0) saturate(100%) invert(12%) sepia(93%) saturate(5449%) hue-rotate(244deg) brightness(66%) contrast(135%);
		opacity: 0;
	}
}
.LOW-ACC {
	z-index: 0;
	&::after {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-image: url(../../../assets/graphics//battle/animations/statEffect.gif);
		background-repeat: no-repeat;
		background-size: 100%;
		animation-name: stat-handler;
		animation-duration: 1.4s;
		animation-delay: 1200ms;
		transform:  scale(0.8) translateY(90px);
		filter: brightness(0) saturate(100%) invert(42%) sepia(4%) saturate(5873%) hue-rotate(15deg) brightness(90%) contrast(76%);
		opacity: 0;
	}
}

@keyframes stat-handler {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	92% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		display: none;
	}
}

.TACKLE {
	&::after {
		position: absolute;
		content: "";
		width: 150px;
		height: 150px;
		background-image: url(../../../assets/graphics//battle/animations/Tackle_B.png);
		background-repeat: no-repeat;
		animation-name: tackle;
		animation-duration: 0.2s;
		transform: translateY(30px);
		opacity: 0;
	}
}
@keyframes tackle {
	0% {
		opacity: 0.8;
	}
	99% {
		opacity: 0.9;
	}
	100% {
		opacity: 0;
	}
}

.SCRATCH {
	&::after {
		position: absolute;
		content: "";
		width: 192px;
		height: 160px;
		background-image: url(../../../assets/graphics//battle/animations/scratchbattle.png);
		background-repeat: no-repeat;
		animation-name: scratch;
		animation-duration: 0.5s;
		animation-timing-function: steps(5);
		transform: translateY(20px);
		opacity: 0;
	}
}
@keyframes scratch {
	0% {
		background-position-x: 0px;
		opacity: 1;
	}
	99% {
		background-position-x: -959px;
		opacity: 1;

	}
	100% {
		background-position-x: -960px;
		opacity: 0;
	}
}

.GROWL {
	&::after {
		position: absolute;
		content: "";
		width: 256px;
		height: 192px;
		background-image: url(../../../assets/graphics//battle/animations/Growl.png);
		background-repeat: no-repeat;
		animation-name: growl;
		animation-duration: 200ms;
		animation-timing-function: steps(2);
		animation-iteration-count: 6;
		transform: translateY(0) translateX(-70px) scale(1);   
		opacity: 0;
	}
}
@keyframes growl {
	0% {
		background-position-x: 0px;
		opacity: 1;
	}
	99% {
		background-position-x: -767px;
		opacity: 1;

	}
	100% {
		background-position-x: -768px;
		opacity: 0;
	}
}

.STRINGSHOT {
	&::after {
		content: "";
		position: absolute;
		background-image: url(../../../assets/graphics//battle/animations/stringshot.png);
		background-repeat: no-repeat;
		animation-name: stringshot;
		animation-duration: 700ms;
		top: 0;
		left: 0; 
		width:300px;
		height: 300px;
		opacity: 0;
	}
}
@keyframes stringshot {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		transform: translateX(-100px);
		opacity: 0;
	}
}

.PECK {
	&::after {
		position: absolute;
		content: "";
		width: 150px;
		height: 150px;
		background-image: url(../../../assets/graphics//battle/animations/peck.png);
		background-repeat: no-repeat;
		animation-name: peck;
		animation-duration: 0.2s;
		transform: translateY(80px);
		opacity: 0;
	}
}
@keyframes peck {
	0% {
		opacity: 0.8;
	}
	99% {
		opacity: 0.9;
	}
	100% {
		opacity: 0;
	}
}

.thunder-shock {
	width: 160px;
	height: 192px;
	background-image: url(../../../assets/graphics/battle/animations/T.Shock.png);
	background-repeat:no-repeat;
	animation-name: thunder-shock;
	animation-duration: 0.8s;
	animation-iteration-count: 2;
	animation-timing-function: steps(6);
	transform: scale(2) translateY(30px);
	z-index: 1;
	opacity: 0;
}

@keyframes thunder-shock {
	0% {
		background-position-x: 0px;
		opacity: 1;
	}
	99% {
		background-position-x: -1151px;
		opacity: 1;

	}
	100% {
		background-position-x: -1152px;
		opacity: 0;
	}
}


.EMBER {
	&::after {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-image: url(../../../assets/graphics//battle/animations/ember.gif);
		background-repeat: no-repeat;
		background-size: 70%;
		animation-name: ember;
		animation-duration: 1.4s;
		transform:  scale(0.8) translateY(40px);
		opacity: 0;
	}
}
@keyframes ember {
	0% {
		opacity:0;
	}
	10% {
		opacity:1;
	}
	99% {
		opacity:1;
	}
	100% {
		opacity: 0;
	}
}

.HARDEN {
	&::after {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-image: url(../../../assets/graphics//battle/animations/harden.gif);
		background-repeat: no-repeat;
		background-size: 70%;
		animation-name: harden;
		animation-duration: 1.1s;
		transform:  scale(0.8) translateY(40px);
		opacity: 0;
	}
}
@keyframes harden {
	0% {
		opacity:0;
	}
	10% {
		opacity:0.8;
	}
	90% {
		opacity:0.9;
	}
	100% {
		opacity: 0;
	}
}


.FURYATTACK {
	&::after {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		object-fit: contain;
		background-image: url(../../../assets/graphics//battle/animations/furryattack.png);
		background-repeat: no-repeat;
		background-size: 60%;
		animation-name: furyattack;
		background-position-x: right;
		background-position-y:center;
		animation-duration: 0.3s;
		transform:  scale(0.8) translateY(40px);
		opacity: 0;
	}
}
@keyframes furyattack {
	0% {
		opacity:0;
	}
	10% {
		opacity:1;
	}
	99% {
		opacity:1;
	}
	100% {
		opacity: 0;
	}
}

.LICK {
	width: 192px;
	height: 200px;
	background-image: url(../../../assets/graphics/battle/animations/lick.png);
	background-repeat:no-repeat;
	animation-name: lick;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-timing-function: steps(3);
	transform: translateY(110px);
	z-index: 1;
	opacity: 0;
}
@keyframes lick {
	0% {
		background-position-x: 0px;
		transform: translateY(110px);
		opacity: 1;
	}
	99% {
		background-position-x: -575px;
		transform: translateY(80px);
		opacity: 1;
	}
	100% {
		background-position-x: -576px;
		opacity: 0;
	}
}

.SPITE::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.715);
	animation: spite 1.6s;
	z-index: 9;
	opacity: 0;
}

@keyframes spite {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		display: none;
	}
}

.LEER {
	width: 192px;
	height: 200px;
	background-image: url(../../../assets/graphics/battle/animations/leer.png);
	background-repeat:no-repeat;
	animation-name: lick;
	animation-delay: 0.1s;
	animation-duration: 0.6s;
	animation-iteration-count: 1;
	animation-timing-function: steps(5);
	z-index: 99;
	opacity: 0;
}
@keyframes lick {
	0% {
		background-position-x: 0px;
		opacity: 1;
	}
	99% {
		background-position-x: -959px;
		opacity: 1;
	}
	100% {
		background-position-x: -960px;
		opacity: 0;
	}
}

.KARATECHOP {
	
		background-image: url(../../../assets/graphics//battle/animations/blackpunches.png);
		background-repeat: no-repeat;
		animation-name: karatechop;
		animation-duration: 1s;
		transform-origin: center;

}
@keyframes karatechop {
	0% {
		transform: scale(0) translateY(30px);
	}
	90% {
		transform: scale(1.3)translateY(30px);
	}
	100% {
		transform: scale(1)translateY(30px);
	}
}
.VINEWHIP {
	width: 192px;
	height: 200px;
	background-image: url(../../../assets/graphics/battle/animations/VINEWHIP.png);
	background-repeat:no-repeat;
	animation-name: vinewhip;
	animation-duration: 0.1s;
	animation-iteration-count: 12;
	animation-timing-function: steps(4);
	z-index: 1;
	opacity: 0;
}
@keyframes vinewhip {
	0% {
		background-position-x: 0px;
		opacity: 1;
		transform: translateX(100px) translateY(0);
	}
	99% {
		background-position-x: -767px;
		opacity: 1;
	}
	100% {
		background-position-x: -768px;
		transform: translateX(0) translateY(100px);
		opacity: 0;
	}
}

