.background-filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    user-select: none;
    pointer-events: none;
}
.particle_background {
    position: absolute;
    width: 200%;
    height: 200%;
    top: -288px;
}
.background-filter__fire {
    background-color: rgba(255, 0, 0, 0.3);
    background-image: url(../../../../../assets//graphics/weather/fire_particles.png);
    background-repeat: repeat;
    animation: particle-background 5s infinite linear;
    animation-delay: 500ms;
    opacity: 0;
    z-index: 0;
}

@keyframes particle-background {
	0% {
		background-position-x: 0px;
		background-position-y: 0px;
        opacity: 1;
	}
	100% {
	  	background-position-x: -1024px;
		background-position-y: 576px;
        opacity: 1;
	}
}