.top-nav-bar__settings {
	position: relative;
	cursor: pointer;
	.settings {
		display: flex;
		align-items: center;
		padding: 2px;
		border-radius: 3px;
		background-color: rgba($desk-60, 0);
		transition: $short-animation;
		&:hover {
			background-color: rgba($desk-60, 0.4);
		}
		&:active {
			background-color: rgba($desk-60, 0.5);
			-webkit-box-shadow: inset 3px 3px 5px 3px rgba(0, 0, 0, 0.15);
			box-shadow: inset 3px 3px 5px 3px rgba(0, 0, 0, 0.15);
		}
		.settings-img {
			width: 18px;
			height: 18px;
			margin-left: 5px;
			text-align: center;
			user-select: none;
			line-height: 16px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				filter: brightness(0) saturate(100%) invert(12%) sepia(75%) saturate(1915%) hue-rotate(208deg) brightness(96%) contrast(89%);
			}
		}
		.language-container {
			padding-top: 1px;
		}
		.network-container {
			transform: scale(1.05) translateY(-1px);
			filter: brightness(0) saturate(100%) invert(12%) sepia(75%) saturate(1915%) hue-rotate(208deg) brightness(96%) contrast(89%);
		}
		.batterie-container {
			transform: rotate(90deg) translateX(-3px);
			margin-left: 3px;
			img {
				filter: none;
			}
		}
	}

	// Display settings details
	.settings-details {
		position: absolute;
		top: 40px;
		right: 10px;
		width: 200px;
		animation: window-fade-up 100ms both ease-in-out;
		color: $desk-70;
		font-weight: 600;
		background-color: rgba($af-gray-00, 0.7);
		backdrop-filter: blur(22px);
		cursor: default;
		user-select: none;
		.settings-img {
			display: flex;
			align-items: center;
			.details {
				width: 19px;
				height: 19px;
				transform: rotate(90deg);
				margin-right: 5px;
			}
		}
		&__header {
			display: flex;
			justify-content: space-between;
			padding: 8px;
		}
		&__content--setting {
			background: none;
			width: 100%;
			transition: $short-animation;
			border-radius: 5px;
			.setting {
				display: flex;
				align-items: center;
				justify-content: space-around;
				width: 100%;
				padding: 5px 10px;
				margin: 5px 0;
				color: $af-gray-20;
				img {
					width: 21px;
					height: 21px;
					object-fit: cover;
					filter: brightness(0) saturate(100%) invert(12%) sepia(75%) saturate(1915%) hue-rotate(208deg) brightness(96%) contrast(89%);
				}
				// .volume-invert {
				// 	filter: grayscale(1) invert(1);
				// }
				// .volume-to-mute {
				// 	border-radius: 50%;
				// 	cursor: pointer;
					
				// }
				.sunflower {
					// filter: invert(1);
					width: 17px;
					height: 17px;
				}
				input[type="range"] {
					-webkit-appearance: none;
					appearance: none;
					margin-left: 8px;
					height: 6px;
					background: $desk-40;
					border-radius: 5px;
					cursor: pointer;
				}
				input[type="range"]::-webkit-slider-thumb {
					-webkit-appearance: none;
					appearance: none;
					height: 15px;
					width: 15px;
					border-radius: 50%;
					background: $desk-60;
					cursor: pointer;
					box-shadow: 0 0 2px 0 #555;
					transition: background 0.3s ease-in-out;
				}
				p {
					padding: 7px 12px;
					width: 75px;
					cursor: pointer;
					transition: $short-animation;
					color: $desk-70;
					font-weight: 500;
					border-radius: 3px;
					&:hover {
						filter: brightness(1.3);
					}
					&:active {
						-webkit-box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
						box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
					}
				}
				.active-language {
					background-color: $desk-60;
					color: $af-gray-10;
					font-weight: 500;
				}
				.dark-mode {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 130px;
					padding: 6px 0;
					cursor: pointer;
					transition: $short-animation;
					color: $desk-70;
					font-weight: 500;
					border-radius: 3px;
					&:hover {
						filter: brightness(1.3);
					}
					&:active {
						-webkit-box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
						box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
					}
					img {
						width: 18px;
						height: 18px;
						margin-right: 7px;
						filter: invert(12%) sepia(75%) saturate(1915%) hue-rotate(208deg) brightness(96%) contrast(89%);
					}
				}
				.dark-mode-active {
					background-color: $desk-60;
					color: $af-gray-10;
					font-weight: 400;
					img {
						filter: invert(0.9);
					}
				}
				.footer-ico {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 26px;
					height: 26px;
					padding: 1px;
					background-color: rgba($af-gray-10, 0);
					border-radius: 50%;
					cursor: pointer;
					transition: $short-animation;
					&:hover {
						background-color: rgba($af-gray-10, 0.4);
					}
					img {
						height: 18px;
						width: 18px;
						border-radius: 0;
					}
				}
			}
		}
	}
	.dark-moded {
		background-color: rgba($desk-70, 0.7);
		backdrop-filter: blur(22px);
		p {
			color: $af-gray-00;	
		}
		.setting {
			p {
				color: $af-gray-00;
			}
			img {
				filter: brightness(0) saturate(100%)  invert(1);
			}
			input[type="range"] {
				-webkit-appearance: none;
				appearance: none;
				margin-left: 8px;
				height: 6px;
				background: $desk-20;
				border-radius: 5px;
				cursor: pointer;
			}
			input[type="range"]::-webkit-slider-thumb {
				-webkit-appearance: none;
				appearance: none;
				height: 15px;
				width: 15px;
				border-radius: 50%;
				background: $af-gray-00;
				cursor: pointer;
				box-shadow: 0 0 2px 0 #555;
				transition: background 0.3s ease-in-out;
			}
		}
		
	}
}

@keyframes window-fade-up {
	0% {
		opacity: 0;
		transform: translateY(-50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
