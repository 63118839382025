.comments-container {
	padding: 10px 0;
	margin: 0 20px;
	border-top: 1px solid rgba(0, 0, 0, 0.2);

	// user form
	h5 {
		text-align: right;
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: 600;
		color: $fds-gray-45;
		cursor: pointer;
	}
	.press-enter {
		font-size: 12px;
		margin: 3px 0 0 30px;
	}
	.comment-template {
		background-color: $log-background;
		width: 100%;
		padding: 10px;
		border-radius: 20px;
	}
	.user-comment-form {
		display: flex;
		.user-pic {
			position: relative;
			img {
				width: 32px;
				height: 32px;
				object-fit: cover;
				border-radius: 50%;
				margin-right: 10px;
			}
			.green-dot {
				position: absolute;
				top: 20px;
				right: 8px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				border: 1px solid #fff;
				background-color: #31a24c;
			}
		}
		.comment-form {
			position: relative;
			width: 100%;
			button {
				position: absolute;
				right: 2px;
				width: 42px;
				height: 100%;
				padding: 4px 3px 0 0;
				border: 2px solid $log-background;
				border-radius: 50%;
			}
			.send-btn {
				background-color: #fff;
				cursor: pointer;
				&:hover {
					& img {
						filter: invert(37%) sepia(32%) saturate(6207%) hue-rotate(202deg) brightness(90%) contrast(94%);
					}
				}
				img {
					width: 22px;
					filter: invert(37%) sepia(32%) saturate(6207%) hue-rotate(202deg) brightness(98%) contrast(94%);
				}
			}
			.inactif-btn {
				background-color: #fff;
				img {
					width: 22px;
					user-select: none;
					filter: invert(0.6);
				}
			}
			input {
				&::placeholder {
					font-size: 15px;
				}
			}
		}
	}
	::-webkit-scrollbar {
		width: 3px;
	}
	::-webkit-scrollbar-track {
		background: $fds-gray-20;
	}
	::-webkit-scrollbar-thumb {
		background: $fds-gray-45;
		cursor: pointer;
	}
	::-webkit-scrollbar-thumb:hover {
		background: $fds-gray-30;
	}
	// comments section
	.comments-list {
		margin-top: 15px;
		max-height: 320px;
		overflow-y: scroll;
		img {
			width: 32px;
			height: 32px;
			object-fit: cover;
			border-radius: 50%;
			margin-top: 7px;
			margin-right: 10px;
			cursor: pointer;
		}
		.editor-container {
			padding: 6px 12px;
			border-radius: 12px;
		}
		.comment-container {
			position: relative;
			display: flex;
			padding-right: 10px;
			h6 {
				font-size: 13px;
				font-weight: 600;
			}
			p {
				font-size: 13px;
			}
			.edit-comment {
				position: absolute;
				visibility: hidden;
				display: flex;
				justify-content: center;
				align-items: center;
				top: 0;
				right: -25px;
				width: 30px;
				height: 30px;
				padding-bottom: 10px;
				border-radius: 50%;
				font-size: 22px;
				font-weight: 400;
				background-color: #fff;
				cursor: pointer;
				transition: 200ms;
				z-index: 2;
				&:hover {
					background-color: $fds-gray-10;
				}
				.edit-comment-options-container {
					position: absolute;
					width: 200px;
					top: -5px;
					right: 35px;
					padding: 5px;
					background-color: #fff;
					border-radius: 5px;
					-webkit-box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.2);
					box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.2);
					.edit-comment-options {
						.option {
							padding: 5px;
							width: 100%;
							background-color: #fff;
							border-radius: 5px;
							transition: 200ms;
							cursor: pointer;
							&:hover {
								filter: brightness(0.95);
							}
							p {
								font-size: 15px;
								font-weight: 500;
								color: $fds-gray-90;
							}
						}
						.delete-confirmation {
							background-color: rgba(255, 0, 0, 0.6);
							p {
								color: #fff;
							}
							&:hover {
								background-color: rgba(255, 0, 0, 0.65);
							}
						}
					}
				}
				.comment-edit-form {
					position: absolute;
					top: 0;
					right: 0;
					width: 400px;
					@include fd-mobile-breakpoint {
						display: none;
					}
					input {
						background-color: $fds-gray-20;
						padding: 10px;
						min-height: 48px;
						width: 365px;
						border-radius: 13px;
					}
					input[type="submit"] {
						display: none;
					}
				}
			}
		}
	}
	.comment-item {
		&:hover {
			& .edit-comment {
				visibility: visible;
			}
		}
		padding-right: 40px;
	}
	.comment-interactions {
		position: relative;
		font-size: 12px;
		font-weight: 700;
		color: $fds-gray-70;
		margin: 3px 0 10px 60px;
		span {
			margin-right: 15px;
		}
		.modified {
			font-weight: 600;
		}
		.date {
			font-weight: 500;
		}
		.liked,
		.to-like {
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
		.liked {
			color: $fds-blue-60;
		}
		.comment-likes {
			position: absolute;
			right: 15px;
			top: -15px;
			background-color: #fff;
			border-radius: 10px;
			padding: 1px 4px;
			-webkit-box-shadow: 1px 3px 15px 2px rgba(0, 0, 0, 0.15);
			box-shadow: 1px 3px 15px 2px rgba(0, 0, 0, 0.15);
			z-index: 0;
			.likes-count {
				display: flex;
				align-items: center;
				height: 20px;
				img {
					width: 18px;
					height: 18px;
					object-fit: cover;
					margin: 0 5px 0 0;
				}
				span {
					margin: 0;
				}
			}
		}
	}
}
