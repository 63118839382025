.item-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    .player-money {
        position: absolute;
        display: flex;
        top: 175px;
        left: 90px;
        z-index: 2;
        padding: 14px 4px 4px 12px;
        color: rgb(44, 44, 44);    
        text-shadow: -1px 1px 1px rgb(49 49 49 / 30%);
        font-size: 11px;
        user-select: none;
        &::before {
            content: "";
            position: absolute;
            width: 105%;
            height: 32px;
            border: 3px solid #7c78a4;
            outline: 2px solid #2a3214;
            border-radius: 3px;
            top: 0;
            left: 0;
            background: white;
            z-index: -1;
        }
    }
    .vibring {
        animation: vibring 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
    .red-fade {
        display: flex;
        animation: red-fade 0.33s cubic-bezier(.36,.07,.19,.97) forwards;
    }
    .keyboard-menu {
        width: 47%;
        height: 255px;
        top: 8px;
        right: 12px;
        padding: 8px;
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 78%;
            border: 3px solid #7c78a4;
            outline: 2px solid #2a3214;
            border-radius: 3px;
            top: 0;
            left: 0;
            background: white;
            z-index: -1;
        }
        .keyboard-menu-option {
            position: relative;
            margin: 0 0 8px 22px;
            .item-ico {
                position: absolute;
                left: -24px;
                top: 4px;
                width: 16px;
                object-fit: cover;
            }
            button {
              width: 150px;
            }
            .price {
                position: absolute;
                right: 0;
                top: 8px;
                font-size: 10px;
                color: rgb(44, 44, 44);    
                text-shadow: -1px 1px 1px rgb(49 49 49 / 30%);
                cursor: pointer;
            }
        }
        [data-description="Quitter le magasin."] {
            position: absolute;
            left: 0;
            top: 60px;
            background-color: white;
        }
    }
    .description-box {
        width: 508px;
        background-color: white;
	      color: #4467e4;
        p {
          text-shadow: 1px 1px 1px rgba(175, 175, 175, 1);
          font-size: 11px;
        }
    }
}

@keyframes vibring {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
      }
      
      20%, 80% {
        transform: translate3d(2px, 0, 0);
      }
    
      30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
      }
    
      40%, 60% {
        transform: translate3d(4px, 0, 0);
      }
}
@keyframes red-fade {
  to {
    transform: translateY(-20px);
    color: red;
    opacity: 0;
  }
}