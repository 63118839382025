.player-status {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    .trainer-card {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .principal-information {
        position: absolute;
        display: flex;
        top: 52px;
        left: 36px;
        z-index: 1;
        color: white;
        line-height: 18px;
        text-shadow: 1px 1px 1px rgb(23, 40, 96);
        font-size: 12px;
        .right-part {
            margin-left: 88px;
            img {
                height: 156px;
                object-fit: cover;
            }
        }
    }
    .badges {
        position: absolute;
        display: flex;
        top: 240px;
        left: 32px;
        z-index: 1;
        color: white;

    }
}