.team {
	display: flex;
	position: absolute;
	top: 6px;
	gap: 4px;
}
.pokeball-battle {
	width: 16px;
	height: 16px;
}
.team[data-team="player"] {
	left: 6px;
}
.team[data-team="enemy"] {
	right: 6px;
}

// start stateful paths as display:none 
.team .dead-pokemon {
	display: none;
}
.team .active-pokemon-indicator {
	display: none;
}

// display them as opted in by data-attributes 
.team [data-dead="true"]  {
	img {
        filter: grayscale(1) brightness(0.8);
    }
}
.team [data-active="true"] .active-pokemon-indicator {
        position: absolute;
        display: block;
        font-size: 9px; 
		margin-left: 3px;
}