.blue-btn {
	background-color: $fds-blue-60;
	color: #fff;
	border-radius: 6px;
	font-size: 20px;
	line-height: 48px;
	padding: 0 16px;
	width: 350px;
	font-weight: 700;
	font-size: 1.15em;
	transition: 300ms;
	cursor: pointer;
	user-select: none;
	@include fd-tablet-breakpoint {
		width: 97%;
	}
	@include fd-tablet-breakpoint {
		font-size: 1.05em;
	}
	&:hover {
		background-color: #1969d2;
	}
}

.green-btn {
	background-color: #42b72a;
	color: #fff;
	border-radius: 6px;
	font-size: 16px;
	line-height: 48px;
	padding: 0 16px;
	width: 226px;
	font-weight: 700;
	transition: 300ms;
	cursor: pointer;
	user-select: none;
	&:hover {
		background-color: #3ca227;
	}
	&:active {
		filter: brightness(0.92);
	}
	@include fd-mobile-breakpoint {
		font-size: 14px;
		width: 191px;
	}
}

.close-window {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: 900;
	width: 40px;
	height: 40px;
	padding: 10px;
	border-radius: 50%;
	color: $fds-gray-80;
	background-color: $fds-gray-10;
	cursor: pointer;
	transition: 300ms;
	user-select: none;
	&:hover {
		filter: brightness(95%);
	}
	&:active {
		transform: scale(0.98);
		-webkit-box-shadow: inset 3px 3px 5px 3px rgba(0, 0, 0, 0.05);
		box-shadow: inset 3px 3px 5px 3px rgba(0, 0, 0, 0.05);
	}
}
.close-window-secondary-edition {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 10px;
	font-weight: 900;
	width: 30px;
	height: 30px;
	padding: 10px;
	border: 1px solid $fds-gray-20;
	border-radius: 50%;
	color: $fds-gray-80;
	background-color: #fff;
	cursor: pointer;
	transition: 300ms;
	user-select: none;
	&:hover {
		filter: brightness(97%);
	}
	&:active {
		transform: scale(0.98);
		-webkit-box-shadow: inset 5px 5px 10px 3px rgba(0, 0, 0, 0.2);
		box-shadow: inset 5px 5px 10px 3px rgba(0, 0, 0, 0.2);
	}
}

.submit-btn {
	background-color: $fds-blue-60;
	color: #fff;
	border-radius: 6px;
	font-size: 15px;
	padding: 8px 16px;
	width: 100%;
	font-weight: 500;
	transition: 300ms;
	cursor: pointer;
	&:hover {
		filter: brightness(95%);
	}
	&:active {
		filter: brightness(90%);
	}
}

.inactif-submit-btn {
	background-color: $fds-gray-20;
	color: $fds-gray-45;
	border-radius: 6px;
	font-size: 15px;
	padding: 8px 16px;
	width: 100%;
	font-weight: 500;
	transition: 300ms;
	cursor: not-allowed;
	user-select: none;
}
