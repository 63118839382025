.post-form-container {
	width: 100%;
	margin-top: 20px;
	.header-form {
		display: flex;
		align-items: center;
		padding: 7px 10px 3px;
		.user-pic {
			display: flex;
			align-items: center;
			img {
				width: 40px;
				height: 40px;
				object-fit: cover;
				border-radius: 50%;
				margin-right: 10px;
			}
			&:hover {
				filter: brightness(0.95);
			}
		}
		.vanilla-input {
			width: 100%;
			padding: 10px 15px;
			background-color: $log-background;
			border-radius: 40px;
			font-size: 16px;
			font-weight: 400;
			color: $fds-gray-70;
			user-select: none;
			cursor: pointer;
			transition: 100ms;
			@include fd-tablet-breakpoint {
				font-size: 14px;
			}
			&:hover {
				filter: brightness(0.95);
			}
			&:active {
				filter: brightness(0.92);
			}
		}
	}
	.footer-form {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: space-around;
		padding: 0 15px 10px;
		.complexe-input {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 7px 3%;
			background-color: #fff;
			color: $fds-gray-70;
			font-size: 15px;
			font-weight: 500;
			border-radius: 7px;
			transition: 300ms;
			cursor: pointer;
			font-family: "Noto Sans", sans-serif ;
			@include fd-tablet-breakpoint {
				font-size: 14px;
				padding: 3px 2%;
			}
			&:hover {
				filter: brightness(0.95);
			}
			&:active {
				filter: brightness(0.9);
			}
			img {
				width: 24px;
				height: 24px;
				margin-right: 10px;
			}
			.video-picto {
				filter: invert(39%) sepia(44%) saturate(3859%) hue-rotate(326deg) brightness(97%) contrast(96%);
			}
			.image-picto {
				filter: invert(66%) sepia(18%) saturate(1400%) hue-rotate(82deg) brightness(92%) contrast(85%);
			}
			.mood-picto {
				filter: invert(76%) sepia(99%) saturate(2531%) hue-rotate(338deg) brightness(102%) contrast(94%);
			}
		}
		.mood-props {
			@include fd-tablet-breakpoint {
				display: none;
			}
		}
	}
}

.form-inputs-background {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	z-index: 99;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	.form-inputs-container {
		position: relative;
		width: 70%;
		min-width: 500px;
		max-width: 580px;
		border: 1px solid $fds-gray-10;
		-webkit-box-shadow: 0px 18px 20px 5px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 18px 20px 5px rgba(0, 0, 0, 0.1);
		@include fd-mobile-breakpoint {
			min-width: inherit;
			width: 95%;
		}
	}
	.form-inputs-header {
		padding: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		@include fd-mobile-breakpoint {
			padding: 7px;
			font-size: 15px;
		}
	}
	.user-informations {
		display: flex;
		align-items: flex-start;
		padding: 15px 15px 0;
		.user-pic {
			img {
				width: 40px;
				height: 40px;
				margin: 5px 15px 0 0;
				border-radius: 50%;
				object-fit: cover;
				cursor: pointer;
				transition: 300ms;
				&:hover {
					filter: brightness(0.95);
				}
			}
		}
		h5 {
			color: $fds-gray-100;
			font-weight: 600;
			font-size: 15px;
			margin-bottom: 2px;
		}
		.public-target {
			width: 80px;
			padding: 3px 0 3px 10px;
			display: flex;
			align-items: center;
			background-color: $fds-gray-10;
			font-size: 13px;
			font-weight: 600;
			border-radius: 8px;
			cursor: pointer;
			transition: 300ms;
			&:hover {
				filter: brightness(0.95);
			}
			img {
				width: 12px;
				height: 12px;
				margin-right: 4px;
			}
		}
	}
	.form-inputs-content {
		textarea {
			background-color: #fff;
			width: 100%;
			height: 110px;
			border-radius: 6px;
			padding: 10px;
			font-size: 22px;
			font-weight: 400;
			resize: none;
			@include fd-mobile-breakpoint {
				font-size: 18px;
			}
			&::placeholder {
				color: $text-setting-title;
			}
		}
		.color-emoji-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px;
			img {
				width: 40px;
				height: 40px;
				object-fit: cover;
				cursor: pointer;
			}
			.emoji-picker {
				width: 25px;
				height: 25px;
			}
		}
	}
	.form-inputs-content-add {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 14px;
		margin: 5px 10px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 7px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
		@include fd-mobile-breakpoint {
			flex-direction: column;
		}
		h5 {
			font-size: 15px;
			font-weight: 600;
			color: $fds-gray-100;
			@include fd-mobile-breakpoint {
				font-size: 14px;
			}
		}
		ul {
			display: flex;
			@include fd-mobile-breakpoint {
			}
			li {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 6px;
				width: 32px;
				height: 28px;
				background-color: #fff;
				border-radius: 5px;
				transition: 300ms;
				cursor: pointer;
				@include fd-mobile-breakpoint {
					margin: 5px 13px 0;
				}
				&:active {
					transform: scale(0.99);
					-webkit-box-shadow: inset 5px 5px 10px 3px rgba(0, 0, 0, 0.1);
					box-shadow: inset 5px 5px 10px 3px rgba(0, 0, 0, 0.1);
				}
				&:hover {
					filter: brightness(95%);
				}
				img {
					height: 22px;
					object-fit: cover;
				}
				.image {
					filter: invert(66%) sepia(18%) saturate(1400%) hue-rotate(82deg) brightness(92%) contrast(85%);
				}
				.tag {
					filter: invert(31%) sepia(99%) saturate(2215%) hue-rotate(203deg) brightness(100%) contrast(91%);
				}
				.mood {
					filter: invert(76%) sepia(99%) saturate(2531%) hue-rotate(338deg) brightness(102%) contrast(94%);
				}
				.location {
					filter: invert(52%) sepia(86%) saturate(4203%) hue-rotate(338deg) brightness(99%) contrast(94%);
				}
				.video {
					filter: invert(39%) sepia(44%) saturate(3859%) hue-rotate(326deg) brightness(97%) contrast(96%);
				}
			}
			.mobile-display-none {
				@include fd-mobile-breakpoint {
					display: none;
				}
			}
		}
	}
	.form-inputs-submit {
		padding: 10px;
	}
	// CONTENT
	.form-media-preview {
		padding: 7px;
		margin: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 7px;
		.form-media-preview-container {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			height: 100%;
			min-height: 120px;
			padding: 12px;
			border-radius: 7px;
			background-color: #f7f8fa;
			transition: 100ms;
			&:hover {
				background-color: #e5e5e5;
			}
			&:active {
				filter: brightness(0.95);
			}
		}
		.picto-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background-color: $fds-gray-10;
			&:hover {
				filter: brightness(0.5);
			}
			img {
				width: 18px;
				object-fit: cover;
				filter: invert(0.2);
			}
		}
		label {
			position: absolute;
			top: 0;
			text-align: center;
			width: 100%;
			height: 100%;
			font-size: 16px;
			font-weight: 600;
			color: $fds-gray-100;
			cursor: pointer;
		}
		input[type="file"] {
			display: none;
		}
		.close-window-secondary-edition {
			position: absolute;
			right: 5px;
			top: 5px;
			z-index: 9;
		}
		.img-preview-container {
			img {
				width: 100%;
				height: 100%;
				max-height: 310px;
				object-fit: cover;
			}
		}
	}
	.form-video-preview {
		.form-video-preview-container {
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;
			padding: 5px;
			border-radius: 7px;
			background-color: #f7f8fa;
			transition: 100ms;
			&:hover {
				background-color: #e5e5e5;
			}
			.video-picto {
				width: 20px;
				height: 20px;
				margin-right: 10px;
				filter: invert(0.45);
			}
			.video-link {
				padding: 5px;
				width: 85%;
				border-radius: 10px;
				font-size: 16px;
			}
			.close-window-secondary-edition {
				position: absolute;
				right: 5px;
				top: 5px;
			}
			.video-preview-container {
				display: flex;
				width: 100%;
				iframe {
					aspect-ratio: 16 / 9;
					margin: 0 auto;
				}
			}
		}
	}
	.emoji-picker-container {
		position: absolute;
		top: 120px;
		right: -20px;
		z-index: 99;
		.close-window-secondary-edition {
			position: absolute;
			right: -10px;
			top: -10px;
			z-index: 9;
		}
		.epr-preview {
			display: none !important;
		}
	}
}
