.profil-page {
	background-color: #fff;
	padding-bottom: 30px;
}
.update-profil-page {
	max-width: 1250px;
	margin: 0 auto;
	background-color: #fff;
	.pictures-modifications {
		.banner-container {
			position: relative;
			height: 30vw;
			min-height: 150px;
			max-height: 370px;
			border-bottom-left-radius: 7px;
			border-bottom-right-radius: 7px;
			background: linear-gradient(0deg, rgb(124, 126, 129) 5%, rgba(240, 242, 245, 1) 25%);
			cursor: pointer;
			.banner-pic-container {
				width: 100%;
				height: 100%;
				border-bottom-left-radius: 7px;
				border-bottom-right-radius: 7px;
				object-fit: cover;
			}
			.banner-modificator {
				position: absolute;
				right: 30px;
				bottom: 15px;
				button {
					display: flex;
					align-items: center;
					background-color: #fff;
					padding: 6px 10px;
					font-size: 15px;
					font-weight: 500;
					border-radius: 5px;
					cursor: pointer;
					transition: 300ms;
					&:hover {
						filter: brightness(97%);
					}
					&:active {
						transform: scale(0.98);
						-webkit-box-shadow: inset 3px 3px 5px 3px rgba(0, 0, 0, 0.05);
						box-shadow: inset 3px 3px 5px 3px rgba(0, 0, 0, 0.05);
					}
				}
				.camera-picto {
					width: 16px;
					height: 16px;
					margin-right: 7px;
					filter: invert(15%);
					@include fd-tablet-breakpoint {
						margin-right: 0;
					}
				}
				@include fd-tablet-breakpoint {
					p {
						display: none;
					}
				}
			}
		}
		.profil-pic-container {
			min-height: 100px;
			padding: 0 10px;
			.profil-pic-modificator {
				position: relative;
				.pic-container {
					position: absolute;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 176px;
					height: 176px;
					left: 10px;
					bottom: -84px;
					border: 5px solid #fff;
					border-radius: 50%;
					@include fd-tablet-breakpoint {
						left: 50%;
						transform: translateX(-50%);
					}
					img {
						border: 1px solid $fds-gray-30;
						border-radius: 50%;
						width: 168px;
						height: 168px;
						object-fit: cover;
						cursor: pointer;
						&:hover {
							filter: brightness(95%);
						}
					}
				}
				.camera-picto-container {
					position: absolute;
					display: flex;
					justify-content: center;
					align-items: center;
					top: 35px;
					left: 140px;
					width: 36px;
					height: 36px;
					background-color: $fds-gray-20;
					border-radius: 50%;
					transition: 300ms;
					cursor: pointer;
					@include fd-tablet-breakpoint {
						left: 55%;
					}
					@include fd-mobile-breakpoint {
						left: 58%;
					}
					&:hover {
						filter: brightness(95%);
					}
					&:active {
						transform: scale(0.98);
						-webkit-box-shadow: inset 3px 3px 5px 3px rgba(0, 0, 0, 0.05);
						box-shadow: inset 3px 3px 5px 3px rgba(0, 0, 0, 0.05);
					}
					img {
						width: 20px;
						height: 20px;
						filter: invert(15%);
					}
				}
			}
			.name-and-logout {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 20px 10px 16px 200px;
				@include fd-tablet-breakpoint {
					flex-direction: column;
					padding: 90px 10px 16px 20px;
				}
				h1 {
					font-size: 2.1em;
					text-align: center;
				}
				a h5 {
					color: $fds-gray-70;
					height: 5px;
					@include fd-tablet-breakpoint {
						text-align: center;
					}
				}
				.logout {
					@include fd-tablet-breakpoint {
						margin-top: 25px;
					}
					button {
						display: flex;
						align-items: center;
						background-color: $fds-gray-10;
						padding: 6px 10px;
						font-size: 15px;
						font-weight: 500;
						border-radius: 5px;
						cursor: pointer;
						transition: 300ms;
						font-family: "Noto Sans", sans-serif ;
						&:hover {
							filter: brightness(97%);
						}
						&:active {
							transform: scale(0.99);
							-webkit-box-shadow: inset 3px 3px 5px 3px rgba(0, 0, 0, 0.05);
							box-shadow: inset 3px 3px 5px 3px rgba(0, 0, 0, 0.05);
						}
						.logout-pic {
							width: 16px;
							height: 16px;
							margin-right: 7px;
							filter: invert(15%);
						}
					}
				}
				.follow button {
					display: flex;
					align-items: center;
					text-align: center;
					justify-content: center;
					padding: 7px 20px;
					font-size: 15px;
					font-weight: 500;
					color: $user-btn-text;
					background-color: $user-btn-bg;
					font-size: 16px;
					border-radius: 5px;
					cursor: pointer;
					transition: 300ms;
					font-family: "Noto Sans", sans-serif ;
					@include fd-tablet-breakpoint {
						font-size: 15px;
						margin-top: 10px;
					}
					&:hover {
						filter: brightness(97%);
					}
					&:active {
						filter: brightness(94%);
					}
					img {
						width: 18px;
						height: 18px;
						margin-right: 5px;
						filter: invert(22%) sepia(98%) saturate(4156%) hue-rotate(203deg) brightness(102%) contrast(96%);
					}
				}
			}
		}
	}
	.user-informations {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 15px;
		ul {
			display: flex;
			li {
				padding: 10px 20px;
				border-radius: 5px;
				color: $fds-gray-70;
				font-size: 15px;
				font-weight: 600;
				transition: 300ms;
				cursor: pointer;
				font-family: "Noto Sans", sans-serif ;
				&:hover {
					background-color: $log-background;
				}
			}
			.tablet-display-none {
				@include fd-tablet-breakpoint {
					display: none;
				}
			}
			.mobile-display-none {
				@include fd-mobile-breakpoint {
					display: none;
				}
			}
			.is-active {
				position: relative;
				color: $primary-icon;
				&::after {
					position: absolute;
					bottom: -6px;
					left: 4px;
					content: "";
					height: 3px;
					width: 120px;
					border-radius: 10px;
					background-color: $primary-icon;
					animation: after-fade 400ms forwards;
				}
			}
		}
		.fake-btn {
			button {
				padding: 0 15px 8px;
				margin-right: 5px;
				border-radius: 5px;
				font-size: 20px;
				font-weight: 800;
				color: $fds-gray-90;
				cursor: pointer;
				transition: 300ms;
				&:hover {
					filter: brightness(97%);
				}
				&:active {
					filter: brightness(95%);
					transform: scale(0.99);
				}
			}
		}
	}
}
.shadow-separator {
	border-top: 1px solid $fds-gray-20;
	-webkit-box-shadow: 0px 1px 2px 1px rgba(105, 105, 105, 0.2);
	box-shadow: 0px 1px 2px 1px rgba(105, 105, 105, 0.2);
}

.user-firends-bio-thread {
	background-color: $log-background;
	padding: 0 20px 30px;
	min-height: 440px;
	@include fd-mobile-breakpoint {
		padding: 0;
	}
	.friends-recommendations {
		max-width: 1250px;
		margin: 0 auto;
	}
	.profil-thread {
		display: flex;
		max-width: 1250px;
		margin: 0 auto;
		@include fd-tablet-breakpoint {
			flex-direction: column;
		}
		.informations-container {
			width: 32%;
			min-width: 370px;
			margin: 20px 20px 20px 0;
			@include fd-tablet-breakpoint {
				width: 100%;
				margin-bottom: 0;
			}
			@include fd-mobile-breakpoint {
				min-width: inherit;
			}
			.profil-intro,
			.profil-photo {
				margin-bottom: 20px;
			}
			.profil-intro {
				padding: 10px 15px 5px 15px;
				h3 {
					margin-bottom: 7px;
				}
				.content-value {
					font-size: 12px;
					font-weight: 500;
				}
				.principal-option {
					align-items: center;
					text-align: center;
					width: 100%;
					background-color: $fds-gray-10;
					padding: 7px 0;
					margin: 13px 0 5px;
					border-radius: 6px;
					font-size: 15px;
					font-weight: 600;
					color: $fds-gray-90;
					cursor: pointer;
					transition: 300ms;
					&:hover {
						filter: brightness(95%);
					}
					&:active {
						filter: brightness(92%);
						transform: scale(0.99);
					}
				}
				textarea {
					background-color: $log-background;
					width: 100%;
					height: 90px;
					border-radius: 6px;
					padding: 8px;
					text-align: center;
					font-size: 14px;
					font-weight: 600;
					resize: none;
					border: 1px solid $fds-gray-30;
					&::placeholder {
						color: $text-setting-title;
					}
					&:focus {
						background-color: #fff;
						border: 1px solid $fds-blue-60;
					}
					&:hover {
						background-color: $log-background;
					}
				}
				.car-count {
					text-align: right;
					font-size: 12px;
					font-weight: 400;
					color: $fds-gray-70;
				}
				.buttons-container {
					display: flex;
					justify-content: right;
					.secondary-options {
						align-items: center;
						text-align: center;
						background-color: $fds-gray-10;
						padding: 7px 7px;
						margin: 5px 0 20px 5px;
						border-radius: 6px;
						font-size: 15px;
						font-weight: 600;
						color: $fds-gray-90;
						cursor: pointer;
						transition: 300ms;
						&:hover {
							filter: brightness(95%);
						}
					}
					.submit {
						background-color: $fds-blue-60;
						color: #fff;
					}
				}
			}
			.profil-photo {
				display: flex;
				justify-content: space-between;
				align-items: baseline;
				padding: 12px 15px 15px 15px;
				.fake-link {
					padding: 7px;
					color: $blue-link;
					border-radius: 5px;
					cursor: pointer;
					transition: 200ms;
					&:hover {
						background-color: $log-background;
					}
				}
			}
			.legal {
				display: flex;
				flex-wrap: wrap;
				font-size: 12px;
				@include fd-tablet-breakpoint {
					display: none;
				}
				p {
					margin: 0 5px;
					color: $fds-gray-70;
					cursor: pointer;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		.thread-container {
			width: 100%;
			max-width: 830px;
			.empty-post {
				margin-top: 20px;
				padding: 30px;
				text-align: center;
				h4 {
					color: $fds-gray-80;
					font-size: 20px;
					margin-bottom: 20px;
				}
				button {
					width: 230px;
				}
			}
		}
	}
	.visiting {
		margin-top: 20px;
	}
}
