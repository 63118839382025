.folder-explorer {
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: rgba($desk-70, 0.9);
		backdrop-filter: blur(22px);
		color: $af-gray-20;
		padding: 4px 10px;
		&--title {
			display: flex;
			align-items: center;
			.folder-ico {
				padding: 3px;
				background-color: $af-gray-00;
				width: 16px;
				height: 16px;
				margin-right: 10px;
				border-radius: 50%;
			}
			.text {
				display: flex;
				padding: 3px 10px 3px 6px;
				font-weight: 500;
				font-size: 14px;
				border-radius: 2px;
				user-select: none;
				text-transform: capitalize;
				img {
					width: 16px;
					height: 16px;
					object-fit: cover;
					margin-right: 5px;
					filter: invert(1) brightness(1.2);
				}
			}
		}
	}
	&__body {
		display: flex;
		width: 100%;
		height: 100%;
		&--navbar {
			position: relative;
			width: 30%;
			min-width: 150px;
			&--item {
				display: flex;
				padding: 7px 0 7px 15px;
				cursor: pointer;
				&:hover {
					background-color: $desk-50;
					color: white;
					img {
						filter: brightness(0) saturate(100%) invert(1);
					}
				}
				.navbar-ico {
					width: 15px;
					height: 15px;
					object-fit: cover;
					filter: brightness(0) saturate(100%) invert(12%) sepia(75%) saturate(1915%) hue-rotate(208deg) brightness(96%) contrast(89%);
					margin-right: 7px;
					pointer-events: none;
				}
				p {
					font-weight: 500;
					font-size: 14px;
					user-select: none;
					pointer-events: none;
				}
				.home-ico {
					width: 16px;
					height: 16px;
				}
			}
			.navbar-footer {
				position: absolute;
				width: 100%;
				bottom: 30px;
				left: 0;
			}
			.active {
				background-color: $desk-50;
				.navbar-ico {
					filter: invert(1) brightness(1.4);
				}
				p {
					color: $af-gray-10;
				}
			}
		}
		&--content {
			width: 70%;
			background-color: rgba($af-gray-00, 0.5);
			backdrop-filter: blur(22px);
			color: $af-gray-90;
			::-webkit-scrollbar {
				width: 8px;
			}
			::-webkit-scrollbar-track {
				display: none;	
				// background-color: rgba($af-gray-00, 0.5);
			}
			::-webkit-scrollbar-thumb {
				background: $af-gray-40;
				border-radius: 10px;
			}
			::-webkit-scrollbar-thumb:hover {
				background: $af-gray-60;
			}
			.container {
				display: flex;
				flex-wrap: wrap;
				padding: 12px 2px;
				max-height: 90%;
				overflow-y: scroll;
				.folder {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;
					width: 80px;
					margin: 2px 7px;
					padding: 4px;
					background-color: rgba($desk-50, 0);
					cursor: pointer;
					border-radius: 3px;
					&:hover {
						background-color: rgba($desk-50, 0.2);
					}
					&:focus {
						background-color: rgba($desk-50, 0.2);
					}
					.folder-pic {
						width: 48px;
						height: 48px;
						margin-bottom: 5px;
						object-fit: cover;
						user-select: none;
						pointer-events: none;
						border-radius: 4px;
					}
					p {
						user-select: none;
						font-size: 14px;
						pointer-events: none;
					}
					span {
						font-weight: 900;
					}
					.github {
						filter: invert(64%) sepia(50%) saturate(473%) hue-rotate(158deg) brightness(91%) contrast(88%);
					}
					.linkedin {
						filter: invert(15%) sepia(75%) saturate(1694%) hue-rotate(204deg) brightness(94%) contrast(90%)
					}
					.dark-linkedin {
						filter: invert(76%) sepia(27%) saturate(439%) hue-rotate(170deg) brightness(95%) contrast(93%);
					}
					.redirection {
						position: absolute;
						right: 8px;
						bottom: 34px;
						img {
							width: 15px;
							height: 15px;
							object-fit: contain;
						}
					}
					.redirection.lrdb img {
						filter: brightness(0) saturate(100%) invert(21%) sepia(44%) saturate(659%) hue-rotate(119deg) brightness(92%) contrast(88%);
					}
					.redirection.ep img {
						filter: brightness(0) saturate(100%) invert(63%) sepia(9%) saturate(1057%) hue-rotate(347deg) brightness(97%) contrast(86%);
					}
					.redirection.bl img {
						filter: brightness(0) saturate(100%) invert(8%) sepia(84%) saturate(4288%) hue-rotate(314deg) brightness(100%) contrast(115%);
					}
					.redirection.syrah img {
						filter: brightness(0) saturate(100%) invert(11%) sepia(29%) saturate(4309%) hue-rotate(327deg) brightness(135%) contrast(129%);
					}
					.redirection.github img {
						filter: brightness(0) saturate(100%);
					}
					.redirection.linkedin img {
						filter: brightness(0) saturate(100%);
					}
				}
				.video-preview-container {
					position: absolute;
					top: 110px;
					left: -110px;
					width: 320px;
					height: 190px;
					overflow: hidden;
					pointer-events: none;
					opacity: 0.9;
					animation: video-fade-in 330ms forwards ;
					z-index: 999;
					video {
						width: 100%;
						height: 100%;
						object-fit: cover;
						transform: scale(1.13);
					}
				}
			}
		}
		.dark-mode {
			background-color: rgba($desk-70, 0.7);
			backdrop-filter: blur(22px);
			color: $af-gray-10;
			::-webkit-scrollbar-track {
				display: none;			
			}
		}
	}
}

@keyframes video-fade-in {
	from {opacity: 0;}
	to {opacity: 1;}
}