.principal-nav-bar {
	display: flex;
	position: fixed;
	bottom: 0;
	left: 50%;
	padding: 7px;
	transform: translateX(-50%);
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background-color: rgba($af-gray-00, 0.2);
	backdrop-filter: blur(22px);
	opacity: 0;
    animation: principal-nav-fade-in 1000ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards 1200ms;
	z-index: 1;
	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba($desk-70, 0);
		padding: 5px;
		margin: 0 2px;
		width: 52px;
		height: 52px;
		font-size: 37px;
		border-radius: 3px;
		cursor: pointer;
		transition: $short-animation;
		&:hover {
			background-color: rgba($desk-70, 0.3);
		}
		img {
			width: 46px;
			height: 46px;
			object-fit: cover;
			// filter: brightness(0) saturate(100%) invert(18%) sepia(11%) saturate(7137%) hue-rotate(198deg) brightness(90%) contrast(95%);
		}
	}
	.active {
		position: relative;
		background-color: rgba($desk-70, 0.2);
		&::after {
			position: absolute;
			content: "";
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 10px;
			height: 2px;
			background-color: $af-tertiary;
			border-radius: 5px;
			opacity: 1;
		}
		&:focus {
			&::after {
				opacity: 0;
			}
		}
	}
	.menu-icon {
		background-color: rgba($af-gray-90, 0.4);
		img {
			width: 20px;
			height: 20px;
			object-fit: cover;
			filter: invert(0.92);
		}
	}
}

@keyframes principal-nav-fade-in {
	0% {
		opacity: 0;
		transform: translateY(30px) translateX(-50%);
	}
	100% {
		opacity: 1;
		transform: translateY(0) translateX(-50%);
	}
}