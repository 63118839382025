.left-nav-container {
	position: relative;
	width: 33%;
	ul {
		position: fixed;
		height: 90vh;
		margin-top: 15px;
		padding-left: 15px;
		li {
			width: 100%;
			h6 {
				font-size: 14px;
				font-weight: 600;
				color: $fds-gray-100;
			}
			.link-container {
				display: flex;
				align-items: center;
				margin-top: 6px;
				padding: 5px;
				border-radius: 5px;
				background-color: $log-background;
				cursor: pointer;
				transition: 100ms;
				&:hover {
					filter: brightness(0.95);
				}
				&:active {
					filter: brightness(92%);
				}
				img {
					width: 36px;
					height: 36px;
					object-fit: cover;
					margin-right: 15px;
				}
				.profil-pic {
					border-radius: 50%;
				}
			}
		}
		.legal-links {
			position: absolute;
			bottom: 110px;
			display: flex;
			flex-wrap: wrap;
			width: 240px;
			p {
				margin: 0 5px;
				font-size: 12px;
				color: $fds-gray-70;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
