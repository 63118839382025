.top-nav-bar {
	position: fixed;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 3px 4px;
	background-color: rgba($af-gray-00, 0.3);
	backdrop-filter: blur(22px);
	color: $desk-70;
	font-weight: 600;
	font-size: 14px;
	opacity: 0;
    animation: top-nav-fade-in 1000ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards 1200ms;	
	&__date {
		user-select: none;
	}
	&__left,
	&__settings {
		width: 101px;
	}
}

@keyframes top-nav-fade-in {
	0% {
		opacity: 0;
		transform: translateY(-30px) ;
	}
	100% {
		opacity: 1;
		transform: translateY(0) ;
	}
}