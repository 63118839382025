.contact-form-window {
    padding: 24px;
    h1 {
        color: $desk-50;
        padding: 6px 0;
    }
    h3 {
        font-size: 1em;
        font-weight: 500;
        color: $desk-70;
        margin-bottom: 12px;
    }
    .grey-separator {
        background-color: $desk-70;
    }
    form {
        text-align: center;
        color: $desk-70;
        border-radius: 7px;
        .form {
            display: flex;
            text-align: left;
            flex-direction: column;
            label {
                position: relative;
                font-size: 1em;
                font-weight: 500;
                .error {
                    position: absolute;
                    bottom: -22px;
                    left: 50%;
                    width: 55%;
                    transform: translateX(-50%);
                    color: $af-red;
                }
                span {
                    color: $desk-70;
                }
            }
            input {
                width: 90%;
                max-width: 500px;
                padding: 6px;
                margin: 0.6em 0 1.5em;
                background-color: rgba(255, 255, 255, 0.5);
                border-radius: 4px;
                font-family: "Ubuntu", sans-serif;
                font-size: 1em;
                color:  $desk-70;
                &:focus-visible {
                    outline: 1px solid  $desk-70;
                }
                &::placeholder {
                    font-size: 1em;
                    font-style: italic;
                    color: $af-gray-40;
                }
            }
            textarea {
                width: 100%;
                height: 122px;
                padding: 7px;
                margin-top: 0.5em;
                background-color: rgba(255, 255, 255, 0.5);
                resize: none;
                border-radius: 4px;
                font-size: 1em;
                color: $desk-70;
                &:focus-visible {
                    outline: 1px solid  $desk-70;
                }
                &::placeholder {
                    font-style: italic;
                    color: $af-gray-40;
                }
            }
        }
        .cta-container {
            margin-top: 12px;
        }
        .message-sent {
            position: absolute;
            width: 100%;
            left: 50%;
            bottom: 145px;
            transform: translateX(-50%);
            color: $af-green;
            animation: disapear 7000ms forwards;
            opacity: 0.8;
        }
        
    }
}

.contact-form-window.dark-mode {
    h1 {
        color: $desk-20;
    }
    h3 {
        color: white;
    }
    label {
        color: $desk-20;
    }
    form .form input,
    form .form textarea {
        background-color: rgba(255, 255, 255, 0.3);
        color: $af-gray-10;
        &::placeholder {
            color: $af-gray-30;
        }
    }
}