.not-found-page {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: $log-background;
	.not-found-container {
		position: absolute;
		text-align: center;
		width: 460px;
		height: 600px;
		top: 40%;
		left: 50%;
		transform: translate(-50%);
		img {
			width: 112px;
			height: 112px;
			object-fit: cover;
		}
		h1 {
			font-size: 20px;
			font-weight: 800;
			margin-top: 10px;
			margin-bottom: 8px;
			color: $fds-gray-70;
		}
		p {
			color: $fds-gray-70;
			margin-bottom: 20px;
		}
		button {
			max-width: 280px;
			font-size: 17px;
			margin-bottom: 20px;
		}
		h2 {
			font-size: 16px;
			color: $fds-blue-60;
			margin-bottom: 10px;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
