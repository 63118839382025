#desktopInterface {
	.app-delimitation {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 99%;
		height: 97%;
		pointer-events: none;
	}
	.draggable-z-index {
		z-index: 101;
	}
	.desktop-wpp {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: -1;
	}
	.desktop-logo {
		position: absolute;
		top: 33%;
		left: 50%;
		transform-origin: center;
		transform:  translate(-50%, -50%) scale(1);
		width: 50%;
		max-width: 666px;
		min-width: 335px;
		object-fit: cover;
		z-index: -1;
		// opacity: 0;
		// animation: desktop-logo-fade-in 1500ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards 1500ms;	
	}
	.dark-logo {
		filter: brightness(0) saturate(100%) invert(87%) sepia(9%) saturate(569%) hue-rotate(314deg) brightness(89%) contrast(92%);
	}
	.desktop-brightness-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		pointer-events: none;
	}
}

@keyframes desktop-logo-fade-in {
	0% {
		opacity: 0;
		transform:  translate(-50%, -50%) scale(0.8);
	}
	100% {
		opacity: 1;
		transform:  translate(-50%, -50%) scale(1);
	}
}