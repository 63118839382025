.dark-mode.info-html {
    h2 {
        color: $af-gray-00;
    }
    h3 {
        color: $af-blue-10;
    }
    p {
        color: $af-gray-00;
    }
}