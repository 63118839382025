.healing-transition {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: #000;
	opacity: 0;
	animation: healing-transition-fade-in 2.6s forwards;
	z-index: 9;
}

@keyframes healing-transition-fade-in {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
    99% {
		opacity: 1;
	}
    100% {
		opacity: 0;
	}
}
@keyframes healing-transition-fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}