.battle {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	image-rendering: pixelated;
	overflow: hidden;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		width: 100%;
		height: 50%;
		user-select: none;
		background-color: #020304;
		z-index: 9;
		animation: scene-reveal-before 1s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 50%;
		user-select: none;
		background-color: #020304;
		z-index: 9;
		animation: scene-reveal-after 1s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	.battle-background {
		image-rendering: pixelated;
		animation: background-reveal 2.4s forwards;
		img {
			width: 150%;
			height: 288px;
		}
	}
	.battle-player,
	.battle-enemy {
		position: absolute;
		width: 160px;
		height: 180px;
		overflow: hidden;
		img {
			pointer-events: none;
		}
	}
	.battle-player {
		left: 0;
		bottom: 60px;
		transform: scale(1.2);
		animation: battle-player 2.5s forwards;
		animation-delay: 1000ms;
		z-index: 5;
		.battle-player-sprite {
			width: 220px;
			height: 160px;
			background-image: url(../../assets/graphics/battle/trainers/player.png);
			background-repeat: no-repeat;
			animation-name: battle-player-sprite;
			animation-duration: 1.7s;
			animation-timing-function: steps(5);
			animation-delay: 400ms;
			opacity: 1;
		}
	}
	.battle-player-pokeball {
		position: absolute;
		top: 90px;
		left: 68px;
		animation-name: battle-player-pokeball;
		animation-duration: 2.1s;
		animation-delay: 900ms;
		opacity: 0;
		.battle-player-pokeball-sprite {
			width: 32px;
			height: 64px;
			background-image: url(../../assets/graphics/battle/start/ball_POKEBALL.png);
			background-repeat: no-repeat;
			animation-name: battle-player-pokeball-sprite;
			animation-duration: 0.1s;
			animation-delay: 800ms;
			animation-iteration-count: 15;
			animation-timing-function: steps(8);
			transform: scale(0.5);
			opacity: 1;
		}
	}
	.battle-enemy {
		right: 0;
		top: 0;
		z-index: 1;
		animation: battle-enemy-leave 4.2s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	.text-container {
		position: absolute;
		width: 100%;
		height: 64px;
		bottom: 0;
		background-color: #275265;
		border: 2px solid rgb(44, 44, 44);
		border-radius: 4px;
		z-index: 1;
	}
}


@keyframes scene-reveal-before {
	0% {
		opacity: 1;
  }
  100% {
	  transform: translateY(-100%);
  }
}
@keyframes scene-reveal-after {
	0% {
	  	opacity: 1;
	}
	100% {
		transform: translateY(100%);
	}
}
@keyframes background-reveal {
	0% {
	  	transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

@keyframes battle-player {
	0% {
	  	transform: translateX(0) scale(1.2);
	}
	99% {
		transform: translateX(-200px) scale(1.2);

	}
	100% {
	  	transform: translateX(-200px) scale(1.2);
	}
}
@keyframes battle-player-sprite {
	0% {
		background-position-x: 0px;
		opacity: 1;
	}
	99% {
		background-position-x: -799px;
		opacity: 1;

	}
	100% {
	  	background-position-x: -800px;
	  	opacity: 1;
	}
}
@keyframes battle-player-pokeball {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	40% {
		transform:translateX(35px) translateY(10px);
		opacity: 1;
	}
	100% {
		transform:translateX(35px) translateY(140px);
		opacity: 1;
	}
}
@keyframes battle-player-pokeball-sprite {
	0% {
		background-position-x: 0px;
		opacity: 1;
	}
	99% {
		background-position-x: -255px;
		opacity: 1;

	}
	100% {
	  	background-position-x: -256px;
	  	opacity: 1;
	}
}
@keyframes battle-enemy-leave {
	0% {
		opacity: 0;
		transform: translateX(-200px);
	}
	40% {
		opacity: 1;
		transform: translateX(0);
	}
	75% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(200px);
	}
}