.readme-template {
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $desk-70;
		color: $af-gray-20;
		padding: 6px 10px;
		&--title {
			display: flex;
			align-items: center;
			pointer-events: none;
			.icon {
				width: 16px;
				height: 16px;
				padding: 1px;
				border-radius: 50%;
				margin-right: 10px;
				background-color: $af-gray-10;
				object-fit: contain;
				font-size: 10px;
			}
		}
	}
	&__body {
		width: 100%;
		height: 100%;
		background-color: rgba($af-gray-00, 0.3);
		position: relative;
		text-align: center;
		align-items: center;
		.container {
			padding: 12px 8px 0;
			max-height: 90%;
			overflow-y: scroll;
			.gray-separator {
				width: 90%;
				height: 1px;
				background-color: $desk-50;
				margin: 0 auto 30px;
			}
			h1 {
				margin-top: 12px;
				margin-bottom: 18px;
				font-size: 28px;
				font-weight: bold;
				color: $desk-50;
			}
			h2 {
				margin-bottom: 20px;
				padding: 0 12px;
				font-size: 16px;
				color: $desk-60;
			}
			h3 {
				font-size: 22px;
				margin-top: 15px;
				margin-bottom: 30px;
				color: $desk-50;
			}
			p {
				color: $desk-70;
				font-weight: 500;
				text-align: justify;
			}
			.icons-container {
				display: flex;
				justify-content: space-around;
				width: 100%;
				img {
					width: 32px;
					height: 32px;
					object-fit: contain;
					filter: brightness(0) saturate(100%) invert(12%) sepia(75%) saturate(1915%) hue-rotate(208deg) brightness(96%) contrast(89%);
				}
			}
		}
	}
	.dark-mode {
		background-color: rgba($desk-70, 0.7);
		// backdrop-filter: blur(22px);
		color: $af-gray-00;
		// ::-webkit-scrollbar-track {
		// 	background: $af-gray-70;
		// }
		h1 {
			color: $desk-20;
		}
		.gray-separator {
			background-color: $af-gray-00;
		}
		.icons-container {
			img {
				filter: brightness(0) saturate(100%) invert(1);
			}
		}
	}
}
